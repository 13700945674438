import { Text, VStack } from "@chakra-ui/react";
import { PageContainer } from "../../components";
import { CardSummaryTabs } from "./CardSummaryTabs";

export function DashboardPage() {
  return (
    <PageContainer>
      <VStack alignItems={"stretch"} spacing={"md"}>
        <Text variant="h3" color="gray.700">
          Cardolies
        </Text>
        <CardSummaryTabs />
      </VStack>
    </PageContainer>
  );
}
