import {
  Box,
  Button,
  Center,
  Flex,
  Text,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useCard } from "../../../../hooks";
import { CardBackgroundWithBodyPortal } from "../CardBackground";

import { FiFastForward as FastForwardIcon } from "react-icons/fi";
import { CardolyLogoName } from "../../../../feature";
import { ConfettiWithDuration } from "./ConfettiWithDuration";
import { useOpeningAnimation } from "../../../../hooks/useOpeningAnimation";

export function BouncingCardWithConfettiAnimation({
  onCompleteOpenAnimation = () => {},
}: OpeningSequenceProps) {
  const { card } = useCard();
  const showConfetti = card?.showConfetti;

  const { handleStopOpeningSequence, openingAnimationIsOpen } =
    useOpeningAnimation();
  const {
    isOpen: confettiIsOpen,
    onClose: confettiOnClose,
    onOpen: confettiOnOpen,
  } = useDisclosure();

  return (
    <>
      {openingAnimationIsOpen && (
        <BouncingCardAnimation
          onCompleteOpenAnimation={() => {
            handleStopOpeningSequence();
            // Start confetti
            confettiOnOpen();
          }}
          onSkip={() => {
            // Conplete entire sequence
            onCompleteOpenAnimation();
          }}
        />
      )}

      {!openingAnimationIsOpen && confettiIsOpen && showConfetti && (
        <ConfettiWithDuration
          onComplete={() => {
            // Stop confetti
            confettiOnClose();
          }}
        />
      )}
    </>
  );
}

interface CardAnimationProps extends OpeningSequenceProps {
  onSkip?(): void;
}
const OPEN_ANIMATION_DURATION_IN_SECONDS = 2;
// const OPEN_ANIMATION_DURATION_IN_SECONDS = 0.5;
function BouncingCardAnimation(props: CardAnimationProps) {
  const [isRest, setIsRest] = useState(true);
  const theme = useTheme();
  const { card } = useCard();
  const title = card?.title;
  const BORDER_RADIUS = "5%";
  const animate = isRest
    ? {
        y: "16px",
      }
    : {
        scale: [1, 2, 2, 1, 1],
        rotate: [0, 0, 180, 180, 0],
        borderRadius: [
          BORDER_RADIUS,
          BORDER_RADIUS,
          "50%",
          "50%",
          BORDER_RADIUS,
        ],
      };
  const transition = isRest
    ? {
        ease: "easeInOut",
        duration: 1.2,
        repeat: Infinity,
        repeatType: "reverse",
      }
    : {
        duration: OPEN_ANIMATION_DURATION_IN_SECONDS,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        onStop() {
          // console.log("somto onStop..");
        },

        onComplete() {
          if (props.onCompleteOpenAnimation) {
            props.onCompleteOpenAnimation();
          }
        },
      };

  return (
    <CardBackgroundWithBodyPortal
      // zIndex={1}
      zIndex={"modal"}
      overflow={"hidden"}
    >
      <Center h="100vh" w="full" position="fixed">
        <motion.div
          style={{
            padding: "0px 24px",
            // background: theme.colors?.green?.["300"],
            border: `1px solid ${theme?.colors?.gray?.["200"]}`,
            background: "#fff",
            boxShadow: theme.shadows?.xl,
            height: "400px",
            width: "400px",
            borderRadius: BORDER_RADIUS,
          }}
          animate={animate}
          // @ts-ignore no problem in operation, although type error appears.
          transition={transition}
        >
          <Flex
            justifyContent="space-between"
            flexDir="column"
            h="full"
            py="lg"
          >
            <Center>
              <Box>
                <CardolyLogoName />
              </Box>
            </Center>

            <Box>
              <Text
                // color="white"
                color="brand.400"
                textAlign="center"
                fontFamily={card?.titleFontFamily}
                fontSize={"xl"}
                fontWeight="bold"
              >
                {title}
              </Text>
            </Box>

            <Center>
              <Button onClick={() => setIsRest(false)}>View Cardoly</Button>
            </Center>
          </Flex>
        </motion.div>
        {/* </ChakraBox> */}
      </Center>
      <Button
        variant="solid"
        colorScheme={"gray"}
        // Align on page bottom right
        pos="fixed"
        right={"lg"}
        bottom={"lg"}
        onClick={props.onSkip}
        // onClick={toCardPageEditorMode}
        rightIcon={<FastForwardIcon />}
      >
        Skip
      </Button>
    </CardBackgroundWithBodyPortal>
  );
}
