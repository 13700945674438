import {
  Box,
  Heading,
  Stack,
  Text,
  Input,
  Button,
  FormErrorMessage,
  FormControl,
  VStack,
  Select,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuth, useRoutes, useToast, useUser } from "../../hooks";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { DesktopOnly, Loader, PageContainer } from "../../components";
import { FiArrowLeft as BackIcon } from "react-icons/fi";
import { AmplifyAuthenticator } from "../Auth";
import {
  OrderType,
  useAddCardToSubscriptionMutation,
  useCreateCardMutation,
} from "../../graphql/generated/schema";
import { useEditedCardFormListener } from "../../hooks/useEditedCardFormListener";
import { FaRegLightbulb } from "react-icons/fa";
import { CardPreview } from "./CardPreview";
import { useEditedCard } from "../../hooks/useEditedCard";
import { SelectCardolyType } from "./SelectCardolyType";
import { sendToSlack } from "../../util/sendToSlack";

const inputSize = "md";

const TITLE_MAX_LENGTH = 190;

const photoCategories: PhotoCategory[] = [
  // "Birthday",
  // "Anniversary",
  // "Thanks",
  // "Congrats",
  // "Love",
  // "Holiday",
  // // "Nature",
  // "Christmas",

  "Birthday",
  "Welcome",
  "Farewell",
  "Thanks",
  "Anniversary",
  "Love",
  "Holiday",
  "Christmas",
  "Fun",
  "Technology",
  "Babies & Children",
  "Landscape",
  "Get Well Soon",
  "Sympathy & Support",
  "Congrats",
  "Nature",
];

export function CreateCard() {
  const { toPaymentPageForProduct, productId, params, toCardPage } =
    useRoutes();
  const [addCardToSubscription] = useAddCardToSubscriptionMutation();
  const {
    reset,
    // setValue,
    getValues,
    register,
    handleSubmit,
    control,
    // getValue,
    formState: { errors, isValid },
  } = useForm<CreateOrEditCardFormInputs>({
    defaultValues: {
      titleFontFamily: "Coming Soon",
      orderType: OrderType.Free,
    },
  });
  useEditedCardFormListener({
    isCreateCardolyFlow: true,
    control,
  });

  const [createCard, { data, loading: isLoading, error }] =
    useCreateCardMutation();
  const isSuccess = !!data;
  const isError = !!error;

  const [step, setStep] = useState<number>(1);

  const { isAuthenticated, userId, user } = useAuth();

  const { name: serverOwnerName, isLoadingUser } = useUser();
  const userEnteredOwnerName = getValues("ownerName");
  const userEnteredReceiverName = getValues("receiverName");
  const ownerName = userEnteredOwnerName || serverOwnerName;
  const showOwnerNameInput = !serverOwnerName && !isLoadingUser;
  const shouldAutomaticallyCreateCard =
    isAuthenticated && isValid && step === 2 && !!ownerName;

  const cardID = data?.createCard.id;

  // Setup toasts
  useToast({
    id: "add-card-error",
    title: "Something went wrong",
    description: error?.message,
    show: isError,
    status: "error",
  });

  useToast({
    id: "add-card-success",
    title: "Success",
    description: "Created card",
    show: isSuccess,
    status: "success",
  });

  const { cardBackground } = useEditedCard();

  const onSubmitCreateCard: SubmitHandler<CreateOrEditCardFormInputs> = (
    data
  ) => {
    const { ownerName, receiverName, title } = data;

    if (ownerName && receiverName && title) {
      delete data.occasion; // remove occasion from card data

      createCard({
        variables: {
          input: {
            ...data,
            ...cardBackground, // add card background info
            ownerName,
            receiverName,
            title,
          },
        },
        update: (cache) => {
          cache.evict({
            fieldName: "getCardsByOwnerCognitoId", // To reflect the latest pagination info from BE
          });
          cache.gc();
        },
      });
    }
  };

  const toLoginStep = () => {
    setStep(2);
  };

  const toCreateCardStep = () => {
    setStep(3);
  };

  const onSubmit = isAuthenticated ? onSubmitCreateCard : toLoginStep;
  const onSubmitBound = handleSubmit(onSubmit);

  useEffect(() => {
    if (isSuccess) {
      try {
        sendToSlack({
          // text: `Cardoly ${cardID}\ncreated by: ${ownerName} (${userId})`,
          text: `${user?.email} | ${ownerName} | ${userId}\ncreated card ${cardID}\nfor ${userEnteredReceiverName}`,
          channel: "cardolies",
        });
      } catch (error) {
        console.error("Error sending to log", error);
      }
    }

    // If we already have a subscription, add the card to the subscription
    if (isSuccess && cardID && params.subscriptionId) {
      addCardToSubscription({
        variables: {
          input: {
            subscriptionId: params.subscriptionId,
            cardId: cardID,
          },
        },
        onCompleted() {
          // // Show payment success page
          // toPaymentPageForProduct(productId, cardID);
          toCardPage({ cardID });
        },
      });
    } else if (
      isSuccess &&
      cardID &&
      getValues("orderType") === OrderType.Solo
    ) {
      toCardPage({ cardID });
    } else if (isSuccess && cardID) {
      // TODO: Grab cardID from created card
      // toSelectCardTypePage({ cardID }); // TODO: Remove all instances of toSelectCardTypePage
      toPaymentPageForProduct(productId, cardID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cardID,
    isSuccess,
    // toCardPage,
    toPaymentPageForProduct,
    productId,
    params.subscriptionId,
    addCardToSubscription,
  ]);

  // If user is creating card from subscription, grab the subscription id from URL params, set it to the card, and navigate to Cardoly after creation
  useEffect(() => {
    if (params.subscriptionId) {
      // setValue("subscriptionId", params.subscriptionId);
    }
  }, [params.subscriptionId]);

  useEffect(() => {
    if (ownerName) {
      reset({ ...getValues(), ownerName });
    }
  }, [ownerName, reset, getValues]);

  // Navigate to step 3; create card step
  useEffect(() => {
    if (shouldAutomaticallyCreateCard) {
      toCreateCardStep();
      onSubmitBound();
    }
  }, [shouldAutomaticallyCreateCard]);

  if (isLoadingUser) {
    return <Loader />;
  }

  return (
    <PageContainer>
      {step === 1 && (
        <HStack spacing={"md"}>
          <Box flex="1">
            <Box shadow="lg" p="8" rounded="xl" w="full">
              <form onSubmit={onSubmitBound}>
                <VStack spacing="lg" alignItems={"stretch"}>
                  <Heading variant={"h3"}>Create a Cardoly</Heading>

                  <section>
                    <Box mb="2">
                      <Text fontWeight="bold">What's the occasion?</Text>
                    </Box>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormControl isInvalid={!!errors?.occasion}>
                          <Select {...field} placeholder="Select occasion">
                            {photoCategories.map((photoCategory) => (
                              <option>{photoCategory}</option>
                            ))}
                            {/* <option>Congrats & Praise</option> */}
                          </Select>
                          <FormErrorMessage>
                            {errors.occasion?.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                      name="occasion"
                    />
                  </section>

                  <section>
                    <Box mb="2">
                      <Text fontWeight="bold">
                        Who are you creating this Cardoly for?
                      </Text>
                    </Box>
                    <Stack direction={["column", "row"]}>
                      <Box flex="1">
                        <Controller
                          control={control}
                          rules={{
                            required: {
                              message: "This field is required",
                              value: true,
                            },
                          }}
                          render={({ field }) => (
                            <FormControl isInvalid={!!errors?.receiverName}>
                              <Input {...field} placeholder="e.g. Mary Smith" />
                              <FormErrorMessage>
                                {errors.receiverName?.message}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                          name="receiverName"
                        />
                      </Box>
                    </Stack>
                  </section>

                  <section>
                    <Box>
                      <Box mb="2">
                        <Text fontWeight="bold">
                          What title do you want shown?
                        </Text>
                      </Box>
                      <FormControl isInvalid={!!errors?.title}>
                        <Input
                          size={inputSize}
                          type="text"
                          placeholder="e.g. Happy Birthday, Get Well Soon, Congratulations"
                          {...register("title", {
                            required: true,
                            maxLength: TITLE_MAX_LENGTH,
                          })}
                        />
                        {errors.title?.type === "required" && (
                          <FormErrorMessage>
                            This field is required
                          </FormErrorMessage>
                        )}
                        {errors.title?.type === "maxLength" && (
                          <FormErrorMessage>
                            The text may not exceed {TITLE_MAX_LENGTH}{" "}
                            characters
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  </section>

                  {showOwnerNameInput && (
                    <section>
                      <Box mb="2">
                        <Text fontWeight="bold">
                          What is your name? or group name?
                        </Text>
                      </Box>
                      <Box flex="1">
                        <FormControl isInvalid={!!errors?.ownerName}>
                          <Input
                            type="text"
                            placeholder="e.g. James Robert or The Marketing Team"
                            size={inputSize}
                            {...register("ownerName", { required: true })}
                          />
                          <FormErrorMessage>
                            This field is required
                          </FormErrorMessage>
                        </FormControl>
                      </Box>
                    </section>
                  )}

                  <Controller
                    control={control}
                    render={({ field }) => (
                      <SelectCardolyType
                        onChange={(isSolo) => {
                          field.onChange(
                            isSolo ? OrderType.Solo : OrderType.Free
                          );
                        }}
                      />
                    )}
                    name="orderType"
                  />

                  <Button
                    isLoading={isLoading}
                    size={inputSize}
                    variant="solid"
                    colorScheme="brand"
                    w="full"
                    type="submit"
                    // isDisabled={!isValid}
                  >
                    Next
                  </Button>
                  <HStack bg="yellow.100" borderRadius={"lg"} px="md" py="sm">
                    <Icon as={FaRegLightbulb} color="blue.500" />
                    <Text color="blue.600" fontWeight={"bold"} fontSize={"sm"}>
                      This can be modified later
                    </Text>
                  </HStack>
                </VStack>
              </form>
            </Box>
          </Box>

          <DesktopOnly flex="2">
            <CardPreview />
          </DesktopOnly>
        </HStack>
      )}

      {step === 2 && (
        <Box p="8" w="full">
          <VStack spacing="lg">
            <VStack spacing="sm">
              <Text variant="h3" textAlign={"center"}>
                Great! Please sign in
              </Text>
              <Text color="gray.500" textAlign={"center"}>
                This will allow you to save, modify, and share your Cardoly
                {/* 😊 */}
              </Text>
            </VStack>
            <AmplifyAuthenticator />
            <Button
              leftIcon={<BackIcon />}
              variant="ghost"
              aria-label="back-button"
              onClick={() => {
                setStep(1);
              }}
            >
              Back
            </Button>
          </VStack>
        </Box>
      )}

      {step === 3 && (
        <VStack>
          <Loader />
          <Text>Creating card...</Text>
        </VStack>
      )}
    </PageContainer>
  );
}
