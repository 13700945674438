import { ChakraProvider } from "@chakra-ui/react";

import { Provider } from "react-redux";

import {
  CardolyCardPage,
  EditCardEntryPage,
  PageNotFound,
  ThanksPage,
} from "./pages";
import { store } from "./state-management";
import { theme } from "./theme";
import { ModalRoot } from "./modals";
import { ScrollToTop } from "./components";

import { AddCardEntryPage } from "./pages/AddCardEntryPage";
import { CreateCard, NavBar, PageLayout, RequireAuth } from "./feature";

// Routing
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { IS_LOCAL_MOCK_ON, ROUTES } from "./constants";
import { DashboardPage } from "./pages/DashboardPage";

// Configure Amplify
import { Authenticator } from "@aws-amplify/ui-react";
import { LoginPage } from "./pages/LoginPage";
import { PaymentPage, MyAccountPage } from "./pages";
import "@aws-amplify/ui-react/styles.css"; // Styles for Auth UI lib
import "./configureAmplify";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./api/apollo-api";

// PostHog
// import { PostHogProvider } from "posthog-js/react";
import { PostHogInitIdentity } from "./PostHogInitIdentity";

// const REACT_APP_PUBLIC_POSTHOG_KEY =
//   "phc_ano1tQlTRfzPNXdTGcKbb40CPAuZaEwwMZVYOsBfHIx";
// const REACT_APP_PUBLIC_POSTHOG_HOST = "https://app.posthog.com";
// const options = {
//   api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
// };

// import Amplify from "aws-amplify";
// import awsExports from "./aws-exports";
// Amplify.configure(awsExports);

// Turn on MSW for mocking requests
if (IS_LOCAL_MOCK_ON) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require("./mocks/browser");
  worker.start();
}

function App() {
  return (
    <Authenticator.Provider>
      {/* <PostHogProvider apiKey={REACT_APP_PUBLIC_POSTHOG_KEY} options={options}> */}
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <ChakraProvider theme={theme}>
            <BrowserRouter>
              <PostHogInitIdentity />
              <ScrollToTop />
              <PageLayout>
                <NavBar />
                <Routes>
                  <Route path={ROUTES.LOGIN} element={<LoginPage />} />
                  <Route
                    path={ROUTES.MY_ACCOUNT}
                    element={
                      <RequireAuth>
                        <MyAccountPage />
                      </RequireAuth>
                    }
                  >
                    <Route
                      path={"user"}
                      element={
                        <RequireAuth>
                          <MyAccountPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={"security"}
                      element={
                        <RequireAuth>
                          <MyAccountPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={"subscription"}
                      element={
                        <RequireAuth>
                          <MyAccountPage />
                        </RequireAuth>
                      }
                    />
                  </Route>
                  <Route path={ROUTES.CREATE_CARD} element={<CreateCard />} />
                  <Route
                    path={"/"}
                    element={
                      <RequireAuth>
                        <DashboardPage />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.DASHBOARD}
                    element={
                      <RequireAuth>
                        <DashboardPage />
                      </RequireAuth>
                    }
                  />
                  <Route path="card">
                    <Route path=":cardID">
                      <Route index element={<CardolyCardPage />} />
                      <Route path="create" element={<AddCardEntryPage />} />
                      <Route path="thanks" element={<ThanksPage />} />
                    </Route>
                  </Route>

                  <Route
                    path={ROUTES.CARD_ENTRY}
                    element={<EditCardEntryPage />}
                  />
                  <Route path={ROUTES.PRICING} element={<PaymentPage />} />
                  <Route
                    path={ROUTES.SELECT_CARD_TYPE.url}
                    element={<PaymentPage />}
                  />
                  <Route
                    path="payment"
                    element={
                      <RequireAuth>
                        <PaymentPage />
                      </RequireAuth>
                    }
                  />
                  {/* <EditEntryPage /> */}
                  {/* <GiphySearch /> */}
                  {/* <MediaGrid /> */}
                  {/* <Route path="*" element={<CardolyCardPage />} /> */}

                  {/* Redirect to home page when invalid route is entered */}
                  {/* <Route path="*" element={<Navigate replace to="/dashboard" />} /> */}
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </PageLayout>
              <ModalRoot />
            </BrowserRouter>
          </ChakraProvider>
        </ApolloProvider>
      </Provider>
      {/* </PostHogProvider> */}
    </Authenticator.Provider>
  );
}

export default App;
