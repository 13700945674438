// TODO: Fetch these from server, so we won't need deploy for new images.
export const imageFiles: ImageFile[] = [
  // Birthday
  { fileName: "bday-4.jpeg", category: "Birthday" },
  { fileName: "bday-5.jpeg", category: "Birthday" },
  { fileName: "bday-6.jpeg", category: "Birthday" },
  { fileName: "bday-7.svg", category: "Birthday" },
  { fileName: "bday-8.svg", category: "Birthday" },
  { fileName: "bday-9.png", category: "Birthday" },
  { fileName: "bday-10.png", category: "Birthday" },
  { fileName: "bday-11.png", category: "Birthday" },
  { fileName: "bday-12.png", category: "Birthday" },
  { fileName: "bday-13.png", category: "Birthday" },
  { fileName: "bday-14.png", category: "Birthday" },
  { fileName: "bday-15.png", category: "Birthday" },
  { fileName: "bday-16.png", category: "Birthday" },
  { fileName: "bday-17.png", category: "Birthday" },
  { fileName: "bday-18.png", category: "Birthday" },
  { fileName: "bday-19.png", category: "Birthday" },
  { fileName: "bday-20.png", category: "Birthday" },
  { fileName: "bday-1.jpeg", category: "Birthday" },
  { fileName: "bday-2.jpeg", category: "Birthday" },
  { fileName: "bday-3.png", category: "Birthday" },
  { fileName: "bday-21.png", category: "Birthday" },
  { fileName: "bday-22.png", category: "Birthday" },
  { fileName: "bday-23.png", category: "Birthday" },

  // Congrats
  { fileName: "thanks-11.png", category: "Congrats" },
  { fileName: "congrats-1.svg", category: "Congrats" },
  { fileName: "congrats-2.svg", category: "Congrats" },
  { fileName: "congrats-3.svg", category: "Congrats" },
  { fileName: "congrats-4.svg", category: "Congrats" },
  { fileName: "congrats-5.png", category: "Congrats" },
  { fileName: "congrats-6.svg", category: "Congrats" },
  { fileName: "congrats-7.png", category: "Congrats" },
  { fileName: "congrats-8.png", category: "Congrats" },
  { fileName: "congrats-9.svg", category: "Congrats" },
  { fileName: "congrats-10.png", category: "Congrats" },
  { fileName: "congrats-11.png", category: "Congrats" },
  { fileName: "congrats-12.png", category: "Congrats" },
  { fileName: "congrats-13.png", category: "Congrats" },
  { fileName: "congrats-14.png", category: "Congrats" },

  // Farewell
  { fileName: "fare-1.svg", category: "Farewell" },
  { fileName: "fare-2.png", category: "Farewell" },
  { fileName: "fare-3.png", category: "Farewell" },
  { fileName: "fare-4.png", category: "Farewell" },
  { fileName: "fare-5.png", category: "Farewell" },
  { fileName: "fare-6.svg", category: "Farewell" },
  { fileName: "fare-7.svg", category: "Farewell" },
  { fileName: "fare-8.png", category: "Farewell" },
  { fileName: "fare-9.png", category: "Farewell" },
  { fileName: "fare-10.png", category: "Farewell" },
  { fileName: "fare-11.png", category: "Farewell" },
  { fileName: "fare-12.png", category: "Farewell" },

  // Anniversary
  { fileName: "anniv-1.jpeg", category: "Anniversary" },
  { fileName: "anniv-2.png", category: "Anniversary" },
  { fileName: "anniv-3.png", category: "Anniversary" },

  // Thanks
  { fileName: "thanks-1.png", category: "Thanks" },
  { fileName: "thanks-2.png", category: "Thanks" },
  { fileName: "thanks-3.png", category: "Thanks" },
  { fileName: "thanks-4.png", category: "Thanks" },
  { fileName: "thanks-5.png", category: "Thanks" },
  { fileName: "thanks-6.png", category: "Thanks" },
  { fileName: "thanks-7.png", category: "Thanks" },
  { fileName: "thanks-8.png", category: "Thanks" },
  { fileName: "thanks-9.svg", category: "Thanks" },
  { fileName: "thanks-10.png", category: "Thanks" },
  { fileName: "thanks-11.png", category: "Thanks" },
  { fileName: "thanks-12.png", category: "Thanks" },
  { fileName: "thanks-13.png", category: "Thanks" },
  { fileName: "thanks-14.png", category: "Thanks" },

  // Welcome
  { fileName: "welcome-1.png", category: "Welcome" },
  { fileName: "welcome-2.png", category: "Welcome" },
  { fileName: "welcome-3.png", category: "Welcome" },
  { fileName: "welcome-4.png", category: "Welcome" },
  { fileName: "welcome-5.jpeg", category: "Welcome" },
  { fileName: "welcome-6.jpeg", category: "Welcome" },
  { fileName: "welcome-7.png", category: "Welcome" },
  { fileName: "welcome-8.png", category: "Welcome" },
  { fileName: "welcome-9.png", category: "Welcome" },
  { fileName: "welcome-10.png", category: "Welcome" },
  { fileName: "welcome-11.png", category: "Welcome" },
  { fileName: "welcome-12.svg", category: "Welcome" },

  // Fun
  { fileName: "fun-19.png", category: "Fun" },
  { fileName: "fun-1.png", category: "Fun" },
  { fileName: "fun-17.png", category: "Fun" },
  { fileName: "fun-3.png", category: "Fun" },
  { fileName: "fun-5.png", category: "Fun" },
  { fileName: "fun-12.png", category: "Fun" },
  { fileName: "fun-7.png", category: "Fun" },
  { fileName: "fun-9.png", category: "Fun" },
  { fileName: "fun-10.png", category: "Fun" },
  { fileName: "fun-11.png", category: "Fun" },
  { fileName: "fun-13.png", category: "Fun" },
  { fileName: "fun-14.png", category: "Fun" },
  { fileName: "fun-15.png", category: "Fun" },
  { fileName: "fun-6.png", category: "Fun" },
  { fileName: "fun-8.png", category: "Fun" },
  { fileName: "fun-4.png", category: "Fun" },
  { fileName: "fun-20.png", category: "Fun" },
  { fileName: "fun-21.png", category: "Fun" },

  // Babies & Children
  { fileName: "fun-18.png", category: "Babies & Children" },
  { fileName: "fun-2.png", category: "Babies & Children" },
  { fileName: "fun-3.png", category: "Babies & Children" },
  { fileName: "fun-16.png", category: "Babies & Children" },
  { fileName: "babies-1.png", category: "Babies & Children" },

  // Landscape
  { fileName: "landscape-1.jpg", category: "Landscape" },
  { fileName: "landscape-2.jpeg", category: "Landscape" },
  { fileName: "landscape-3.jpeg", category: "Landscape" },
  { fileName: "landscape-4.jpeg", category: "Landscape" },
  { fileName: "landscape-5.jpeg", category: "Landscape" },
  { fileName: "landscape-6.jpeg", category: "Landscape" },
  { fileName: "landscape-7.jpeg", category: "Landscape" },
  { fileName: "landscape-8.jpeg", category: "Landscape" },
  { fileName: "landscape-9.jpeg", category: "Landscape" },
  { fileName: "landscape-10.jpeg", category: "Landscape" },
  { fileName: "landscape-11.jpeg", category: "Landscape" },
  { fileName: "landscape-12.jpeg", category: "Landscape" },
  { fileName: "landscape-13.jpeg", category: "Landscape" },
  { fileName: "landscape-14.jpeg", category: "Landscape" },
  { fileName: "landscape-15.jpeg", category: "Landscape" },
  { fileName: "landscape-16.jpeg", category: "Landscape" },
  { fileName: "landscape-17.jpeg", category: "Landscape" },
  { fileName: "landscape-18.jpeg", category: "Landscape" },
  { fileName: "landscape-19.jpeg", category: "Landscape" },
  { fileName: "landscape-20.jpeg", category: "Landscape" },

  // Technology
  { fileName: "tech-1.jpeg", category: "Technology" },
  { fileName: "tech-2.jpeg", category: "Technology" },
  { fileName: "tech-3.jpeg", category: "Technology" },
  { fileName: "tech-4.jpeg", category: "Technology" },
  { fileName: "tech-5.jpeg", category: "Technology" },

  // Get Well Soon
  { fileName: "getwell-1.png", category: "Get Well Soon" },
  { fileName: "getwell-2.png", category: "Get Well Soon" },
  { fileName: "getwell-3.png", category: "Get Well Soon" },

  // Sympathy & Support
  { fileName: "support-6.png", category: "Sympathy & Support" },
  { fileName: "support-7.png", category: "Sympathy & Support" },
  { fileName: "support-1.jpeg", category: "Sympathy & Support" },
  { fileName: "support-2.jpeg", category: "Sympathy & Support" },
  { fileName: "support-3.jpg", category: "Sympathy & Support" },
  { fileName: "support-4.jpeg", category: "Sympathy & Support" },
  { fileName: "support-5.jpeg", category: "Sympathy & Support" },
  { fileName: "fare-11.png", category: "Sympathy & Support" },

  // Thanks
  { fileName: "thanks-1.png", category: "Thanks" },
  { fileName: "thanks-2.png", category: "Thanks" },
  { fileName: "thanks-3.png", category: "Thanks" },
  { fileName: "thanks-4.png", category: "Thanks" },
  { fileName: "thanks-5.png", category: "Thanks" },
  { fileName: "thanks-6.png", category: "Thanks" },
  { fileName: "thanks-7.png", category: "Thanks" },
  { fileName: "thanks-8.png", category: "Thanks" },
  { fileName: "thanks-9.svg", category: "Thanks" },
  { fileName: "thanks-10.png", category: "Thanks" },
  { fileName: "thanks-11.png", category: "Thanks" },
  { fileName: "thanks-12.png", category: "Thanks" },
  { fileName: "thanks-13.png", category: "Thanks" },
  { fileName: "thanks-14.png", category: "Thanks" },

  // Love
  { fileName: "love-1.png", category: "Love" },
  { fileName: "love-2.png", category: "Love" },
  { fileName: "love-3.png", category: "Love" },
  { fileName: "love-4.png", category: "Love" },
  { fileName: "love-5.png", category: "Love" },

  // Holiday
  { fileName: "holiday-1.svg", category: "Holiday" },
  { fileName: "holiday-2.png", category: "Holiday" },
  { fileName: "holiday-3.png", category: "Holiday" },

  // Christmas
  { fileName: "christmas-1.svg", category: "Christmas" },
  { fileName: "christmas-2.svg", category: "Christmas" },
  { fileName: "christmas-3.png", category: "Christmas" },
  { fileName: "christmas-4.png", category: "Christmas" },
  { fileName: "christmas-5.png", category: "Christmas" },
  { fileName: "christmas-6.png", category: "Christmas" },
  { fileName: "christmas-7.png", category: "Christmas" },
  { fileName: "christmas-8.png", category: "Christmas" },
  { fileName: "holiday-1.svg", category: "Christmas" },
  { fileName: "holiday-2.png", category: "Christmas" },
  { fileName: "holiday-3.png", category: "Christmas" },

  // Nature
  { fileName: "nature-1.jpeg", category: "Nature" },
  { fileName: "nature-2.jpeg", category: "Nature" },
  { fileName: "nature-3.jpeg", category: "Nature" },
  { fileName: "nature-4.png", category: "Nature" },
  { fileName: "nature-5.png", category: "Nature" },
  { fileName: "nature-6.png", category: "Nature" },
];

// export const imageFileGroupings: PhotoGrouping = {
//   Birthday: [
//     { id: "bday-1.jpeg", fileName: "bday-1.jpeg", category: "Birthday" },
//     { id: "bday-2.jpeg", fileName: "bday-2.jpeg", category: "Birthday" },
//     { id: "bday-3.png", fileName: "bday-3.png", category: "Birthday" },
//     { id: "bday-4.jpeg", fileName: "bday-4.jpeg", category: "Birthday" },
//     { id: "bday-5.jpeg", fileName: "bday-5.jpeg", category: "Birthday" },
//     { id: "bday-6.jpeg", fileName: "bday-6.jpeg", category: "Birthday" },
//   ],
// };
